$padding: 10px;
$largePadding: $padding * 4;
$margin: 10px;
$borderColor: #efefef;
$breakPointOne: 1100px;
$breakPointTwo: 592px;
$text-color: #373530;
$light-text-color: #73726e;

* {
	box-sizing: border-box;
}

body,
html,
#root {
	height: 100%;
	width: 100%;
	@media screen and (max-width: $breakPointOne) {
		min-height: 100%;
		height: unset;
	}
	a {
		font-weight: 200;
		color: #373530;
		text-decoration: underline;
		:visited {
			color: #efefef;
		}
	}
}

.App {
	height: 100%;
	background-color: #fff;
	color: $text-color;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.column-wrapper {
		overflow: hidden;
		flex: 1;
		flex-wrap: wrap;
		padding: 0 $padding $padding;
		//border-top: $borderColor solid 1px;
		:first-child {
			border-left: solid 1px $borderColor;
		}
		@media screen and (max-width: $breakPointOne) {
			:nth-child(3) {
				border-left: solid 1px $borderColor;
			}
		}
		.column {
			:first-child {
				border-left: 0;
			}
		}
		display: flex;
		flex-direction: row;
		.work-category {
			border-left: 0;
		}
	}

	.header {
		text-align: center;
		padding: $largePadding $padding $padding;
		h1 {
			padding-bottom: $padding;
		}
		p {
			font-size: small;
		}
		h1,
		p {
			font-weight: 200;
		}
		.content-wrapper {
			border-bottom: $borderColor 1px solid;
			padding-bottom: 40px;
		}
	}
}

.column {
	max-height: 100%;
	width: 25%;
	border-right: solid 1px $borderColor;
	.content {
		//max-height: 100px;
		height: 100%;
		overflow: scroll;
		padding: $padding;
		margin: $margin;
		.contact-wrapper {
			text-align: center;
			img {
				max-width: 100%;
				border-radius: 50%;
				padding: 0 $padding * 4 $padding * 4;
			}
			p {
				padding-bottom: $padding;
			}
			a {
				display: block;
				font-weight: 100;
			}
		}
	}
	h1 {
		margin-bottom: $margin * 2;
	}
	p {
		font-weight: 200;
	}

	@media screen and (max-width: $breakPointOne) {
		min-width: 50%;
	}
	@media screen and (max-width: $breakPointTwo) {
		min-width: 100%;
		border-left: solid 1px $borderColor;
	}
}

.work-category {
	padding-bottom: $padding * 2;
	h2 {
		font-weight: 200;
		padding-bottom: calc($padding/2);
	}
	p,
	a {
		font-size: 12px;
	}
	a,
	p {
		color: $light-text-color;
		padding-bottom: calc($padding/2);
		border-left: 0 !important;
		&.title {
			font-size: 14px;
			//font-weight: bold;
		}
		span {
			color: $text-color;
		}
	}
	a {
		font-weight: 200;
		display: inline;
		padding-right: $padding;
		color: #373530;
		text-decoration: underline;
		:visited {
			color: #efefef;
		}
	}
	.single-piece {
		border-left: 0 !important;
		padding: $padding * 2 0 $padding 0;
		border-bottom: solid 1px $borderColor;
	}
}
